import Markdoc from '@markdoc/markdoc'
import React, { useEffect, useState } from 'react'
import { codeToHtml } from 'shiki'
import { useTheme } from '#app/root.tsx'

function Callout({ children }: { children: React.ReactNode }) {
	return <div className="px-2 bg-slate-500 rounded text-white">{children}</div>
}

function Fence({ children, language }: { children: string; language: string }) {
	const theme = useTheme()
	const [__html, setHtml] = useState('')

	useEffect(() => {
		codeToHtml(children, {
			theme: theme === 'light' ? 'github-light' : 'github-dark',
			lang: language,
		}).then(html => {
			setHtml(html)
		})
	}, [children, language, theme])

	return <div dangerouslySetInnerHTML={{ __html }} />
}

export default function renderMarkdown(content: string) {
	const ast = Markdoc.parse(content)
	const transformed = Markdoc.transform(ast, {
		nodes: {
			fence: {
				render: 'Fence',
				attributes: {
					language: {
						type: String,
					},
				},
			},
		},
		tags: {
			callout: {
				render: 'Callout',
				attributes: {},
			},
		},
	})

	return Markdoc.renderers.react(transformed, React, {
		components: {
			Callout,
			Fence,
		},
	})
}
